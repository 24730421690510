import { notification } from 'antd';
import {
  deleteAdhoc,
  deleteRoute,
  getRoutes,
  markRouteOrderAsCompleted,
} from 'api/routes';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';

export const useRoutesWithRangeDate = (params) =>
  useQuery(['routes', params], async () => await getRoutes(params));

type RoutesParams = {
  startDate: string;
  endDate?: string;
};

export const useRoutes = (params: RoutesParams, options?: UseQueryOptions) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useQuery<any, any, any>(
    ['routes', params],
    async () => await getRoutes(params),
    options
  );

export const useDeleteRoutesByIds = (ids: string[]) =>
  useMutation(
    async () => await Promise.all(ids.map((id: string) => deleteRoute(id))),
    {
      onSuccess: () => {
        notification.success({
          message: 'Routes deleted',
        });
      },
    }
  );

export const useMarkRouteOrderAsCompleted = () =>
  useMutation(({ routeId, orderId }: { routeId: string; orderId: string }) =>
    markRouteOrderAsCompleted(routeId, orderId)
  );

export const useMarkRouteOrdersAsCompleted = () =>
  useMutation(
    (items: { routeId: string; orderId: string }[]) =>
      Promise.all(
        items.map((item) =>
          markRouteOrderAsCompleted(item.routeId, item.orderId)
        )
      ),
    {
      onSuccess: () => {
        notification.success({
          message: 'Routes marked as completed',
        });
      },
    }
  );

export const useDeleteAdhoc = () =>
  useMutation(
    ({ routeId, orderId }: { routeId: string; orderId: string }) =>
      deleteAdhoc(routeId, orderId),
    {
      onSuccess: () => {
        notification.success({
          message: 'Adhoc deleted',
        });
      },
    }
  );
