
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Router from 'next/router';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ConfigProvider } from 'antd';
import NProgress from 'nprogress';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { wrapper } from 'store';
import { BaseContext } from 'context/BaseContext';
import useTimeZone from 'hooks/useTimeZone';
import { theme } from 'utils/colors';
import MainPage from '../layouts/main';
import '../public/app.css';
import '../public/overrides.css';
import '../public/static/antd-custom.css';
import '../public/static/nprogress.css';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import getConfig from 'next/config';
import 'antd/dist/antd.less';
Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);
const pathsWithoutLayout = [
    '/login',
    '/home',
    '/external_contacts',
    '/recovery',
    '/client',
    '/caretakers/new',
    '/farmacies/new',
    '/insurances/new',
    '/_error',
    '/signin',
];
const MyApp = ({ Component, ...rest }) => {
    const { store, props } = wrapper.useWrappedStore(rest);
    const { pageProps, router: { pathname }, } = props;
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: () => false
            }
        }
    });
    const { antDesignLocale } = useTimeZone();
    const flagsmithEnv = getConfig().publicRuntimeConfig.FLAGSMITH_KEY;
    return (<FlagsmithProvider flagsmith={flagsmith} options={{
            environmentID: flagsmithEnv
        }}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              {pathsWithoutLayout.includes(pathname) ? (<Component {...pageProps}/>) : (<BaseContext>
                  <MainPage>
                    <ConfigProvider locale={antDesignLocale}>
                      <Component {...pageProps}/>
                    </ConfigProvider>
                  </MainPage>
                </BaseContext>)}
            </Provider>
            <ReactQueryDevtools initialIsOpen={false}/>
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </FlagsmithProvider>);
};
const __Next_Translate__Page__18e286372b3__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18e286372b3__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  